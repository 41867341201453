import * as React from 'react';
import classNames from 'classnames';
import { map, get, isUndefined } from 'lodash';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import styled from 'styled-components';

import { ITableColumn } from './types';
import { CheckBox } from '../controls/checkbox';

export interface IProps {
  rowIndex?: number,
  selectedRowIndex?: number,
  checkedRows?: number[],
  columns?: ITableColumn[],
  idPath?: string,
  data?: { [key: string]: any },
  onCheck?: (rowIndex: number) => void,
  onRowSelected?: (rowIndex: number) => void,
  nameDataPath?: string,
  t?: TTypedTFunction
}

export function DataTableRow(props: IProps) {
  function onCheck() {
    props.onCheck(props.rowIndex);
  }
  const idValue = get(props.data, props.idPath);
  const id = isUndefined(idValue) ? idValue : String(idValue).replace(/\s+/g, '-');
  const checkedRows = props.checkedRows || [];
  const checkboxName = get(props.data, props.nameDataPath) || id;

  const columns = map(props.columns, (column: ITableColumn) => {
    const textAlignClass = column.textAlign ? `text-${column.textAlign}` : '';
    const value = get(props.data, column.dataPath);
    const processor = column.processor ? column.processor(value, props.data, props.t) : value;
    return column.hasCheckbox ? <TableCell
      key={column.dataPath + column.id}
      data-key={column.id}
      className={textAlignClass}>
      <CheckBox className="fancy-checkbox" name={checkboxName} checked={checkedRows.includes(props.rowIndex)} onChange={onCheck} />
      {processor}
    </TableCell> :
      <td
        key={column.dataPath + column.id}
        data-key={column.id}
        className={textAlignClass}>
        {processor}
      </td>;
  });
  return (
    <tr
      data-id={id}
      className={classNames({
        'selected': props.rowIndex === props.selectedRowIndex,
        'clickable': props.onRowSelected ? true : false,
        'selectedCheckbox': checkedRows.includes(props.rowIndex)
      })}
      onClick={() => props.onRowSelected && props.onRowSelected(props.rowIndex)}>
      {columns}
    </tr>
  );
}

const TableCell = styled.td`
  & .checkbox {
    margin-right: 10px;
  }
`;
TableCell.displayName = 'td';
