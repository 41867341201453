import classNames from 'classnames';
import * as React from 'react';
import { isDevelopment } from '../../lib/env';

import { Button, IButtonProps } from './button';

import './chipButton.css';

export interface Props extends IButtonProps {
  active?: boolean,
  dropdownOpen?: boolean,
  count?: number,
  icon?: JSX.Element,
  dataId?: string
}

const getCount = (countFromProps: number) => {
  if (!Number.isInteger(countFromProps)) {
    if (isDevelopment()) {
      throw new Error('count provided to ChipButton must be an integer');
    }
    return Math.floor(countFromProps);
  }
  return countFromProps;
};

export const ChipButton = (props: Props) => {
  const { active, dropdownOpen, className, ...buttonProps } = props;

  const buttonClass = classNames(
    className,
    'chip-button',
    {
      'chip-button--active': active,
      'chip-button--dropdown': dropdownOpen !== undefined,
      'chip-button--dropdown-open': dropdownOpen
    }
  );

  const countValue = props.count !== undefined && getCount(props.count);
  const additionalInfo = <div className="chip-button__additional-info">
    {countValue && <div className="chip-button__count-divider" />}
    {props.icon || ''}
    <div className="chip-button__count-value">{countValue}</div>
  </div>;

  const text = <div className="chip-button__text">
    {props.text}
    {countValue || props.icon ? additionalInfo : null}
  </div>;

  return (
    <Button
      {...buttonProps}
      text={text}
      className={buttonClass}
    />
  );
};
