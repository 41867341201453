import * as React from 'react';
import { map } from 'lodash';
import styled from 'styled-components';
import { SortDirection, ITableColumn } from './types';
import { CheckBox } from '../controls/checkbox';

export interface IProps {
  columns: ITableColumn[],
  sortDirection?: SortDirection,
  sortedColumnIndex?: number,
  onSort?: (index: number, direction: SortDirection, column: ITableColumn) => void,
  toggleSort?: boolean,
  hasCheckBox: boolean,
  checkBoxChecked?: boolean,
  checkBoxOnChange?: () => void
}

export function DataTableHeader(props: IProps) {
  const cells = map(props.columns, (column: ITableColumn, index: number) => {
    const textAlignClass = column.textAlign ? `text-${column.textAlign}` : '';
    if (column.sortable) {
      return <TableHeader data-key={column.id}
        key={column.id + index}
        role="columnheader"
        scope="col"
        onClick={() => { if (props.onSort) props.onSort(index, nextDirection(props.sortDirection, props.toggleSort), column); }}
        style={{ cursor: 'pointer', width: column.width ? `calc(${column.width}${index === 0 && props.hasCheckBox ? ' + 50px' : ''})` : '' }}
        className={textAlignClass} >
        {index === 0 && props.hasCheckBox &&
          <CheckBox
            className="fancy-checkbox"
            name={"table-header-checkbox"}
            checked={props.checkBoxChecked}
            onChange={props.checkBoxOnChange}
          />}
        {column.title}
        <span className='sort-up-down'>
          {getSortIcon(props.sortedColumnIndex === index ? props.sortDirection : undefined)}
        </span>
      </TableHeader>;
    } else {
      return <TableHeader data-key={column.id}
        key={column.id + index}
        scope="col"
        style={{ width: column.width ? `calc(${column.width}${index === 0 && props.hasCheckBox ? ' + 50px' : ''})` : '' }}
        className={textAlignClass} >
        {index === 0 && props.hasCheckBox &&
          <CheckBox
            className="fancy-checkbox"
            name={"table-header-checkbox"}
            checked={props.checkBoxChecked}
            onChange={props.checkBoxOnChange}
          />}
        {column.title}
      </TableHeader>;
    }
  });

  return (
    <thead>
      <tr>
        {cells}
      </tr>
    </thead>
  );
}

function getSortIcon(direction: SortDirection) {
  switch (direction) {
  case 'asc':
    return <><i className={'fas fa-sort-up active-sort'} /><i className={'fas fa-sort-down'} /></>;
  case 'desc':
    return <><i className={'fas fa-sort-up'} /><i className={'fas fa-sort-down active-sort'} /></>;
  default:
    return <><i className={'fas fa-sort-up'} /><i className={'fas fa-sort-down'} /></>;
  }
}

function nextDirection(current: SortDirection, toggle: boolean): SortDirection {
  if (toggle) {
    switch (current) {
    case 'asc': return 'desc';
    case 'desc': return 'asc';
    default: return 'asc';
    }
  }
  switch (current) {
  case 'asc': return 'desc';
  case 'desc': return undefined;
  default: return 'asc';
  }
}

const TableHeader = styled.th`
  & .checkbox {
    margin-right: 10px;
  }
`;
TableHeader.displayName = 'th';
