import React, { useMemo } from 'react';
import { ActionMeta, MultiValue } from 'react-select';
import { components } from "react-select";
import Creatable from 'react-select/creatable';

import { useTranslation } from '@lib/useTypedTranslation';

import './groupTagPicker.css';
import { Option } from './optionPickerLib';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props}>
        {props.selectProps.placeholder}
      </Placeholder>
      {children}
    </ValueContainer>
  );
};

export interface Props {
  groups: string[],
  suggestions: string[],
  setError: (error: string) => void,
  onGroupsChange: (groups: string[]) => void
}

export const MAX_GROUP_LENGTH = 32;

const mapGroupsToTags = (groups: string[]): Option[] => groups.map((g) => ({ value: g, label: g, name: g }));

export const GroupTagPicker = (props: Props) => {
  const { groups, suggestions, setError, onGroupsChange } = props;

  const ns = 'editRecord';
  const { t } = useTranslation(ns);

  const value = useMemo(() => mapGroupsToTags(groups), [groups]);
  const options = useMemo(() => mapGroupsToTags(suggestions), [suggestions]);

  const renderOption = (option: Option) => {
    return <span className='autosuggest__suggestion'>{option?.label}</span>;
  };

  const validate = (input: string) => {
    if (input.includes(',')) {
      setError(t('GROUP_VALIDATION_ERR_COMMAS', { ns }));
      return false;
    }
    if (input.trim().length > MAX_GROUP_LENGTH) {
      setError(t('GROUP_VALIDATION_ERR_LENGTH', { ns, maxLength: MAX_GROUP_LENGTH }));
      return false;
    }

    setError('');
    return true;
  };

  const onCreateOption = (input: string) => {
    const newOption = input.trim().toLowerCase();

    const isValid = validate(newOption);

    if (isValid) {
      onGroupsChange([...groups, newOption]);
    }
  };

  const onChange = (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
    setError('');
    const newGroups = newValue.map(option => option.name);
    onGroupsChange(newGroups);
  };

  return (
    <div className='group-tag-picker' data-id="groupTagPicker">
      <Creatable
        isMulti
        components={{ ValueContainer: CustomValueContainer }}

        value={value}
        options={options}
        onChange={onChange}

        onCreateOption={onCreateOption}
        formatCreateLabel={input => <div>"{input}"</div>}
        noOptionsMessage={() => <div>GROUP_NONE</div>}
        placeholder={t("GROUP_PLACEHOLDER")}

        classNamePrefix='group-tag-picker'
        formatOptionLabel={renderOption}
        unstyled
        backspaceRemovesValue={false}
      />
    </div>
  );
};
