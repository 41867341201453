import { useTranslation } from "@lib/useTypedTranslation";

import { ZeroSignalMap } from "../../../components/intensityMaps/zeroSignal";
import { NoServiceMap } from "../../../components/intensityMaps/noService";
import { Loading } from "../../../components/loading/loading";
import { TimePeriodAndFilterPicker } from "../../../components/timePeriodAndFilterPicker/timePeriodAndFilterPicker";
import { useFilterPicker } from "../../../components/timePeriodAndFilterPicker/useFilterPicker";
import { useTimePeriodPicker } from "../../../components/timePeriodAndFilterPicker/useTimePeriodPicker";
import React from "react";
import { CoverageMap, CoverageMapProps } from "@components/intensityMaps/coverage";
import { OptionPicker } from "@components/controls/optionPicker";
import { ChipButton } from "@components/controls/chipButton";
import { ITranslationKeys } from "@components/i18n/keys";
import { useSimProviderOptions } from "./useSimProviderOption";

export const PoorNoSignalTab = () => {
  const { t } = useTranslation('maps');

  const {
    filter,
    optionsLoading,
    ...filterPickerProps
  } = useFilterPicker();

  const {
    timePeriod,
    ...timePeriodPickerProps
  } = useTimePeriodPicker({ timePeriods: [30, 7, 2] });

  const { simProviderOptions, simProvider, setSimProvider, simProvidersLoading } = useSimProviderOptions();

  return <>
    <div className="core-maps_header">
      <div className="core-maps_headings">
        <h2>{t('VERY_POOR_NO_SERVICE_SUBHEADING')}</h2>
      </div>
      <div className="core-maps_filters">
        <div className="core-maps-options-filters">
          <OptionPicker
            options={simProviderOptions}
            onSelect={setSimProvider}
            current={simProvider}
            disabled={simProvidersLoading}
            selectText={t('SIM_PROVIDERS')}
            id="sim-provider-picker"
          />
        </div>
        <TimePeriodAndFilterPicker
          filter={filter}
          {...filterPickerProps}
          timePeriod={timePeriod}
          {...timePeriodPickerProps}
          className='core-maps_time-and-estate-filters'
        />
      </div>
    </div>
    <Loading isLoading={optionsLoading || simProvidersLoading} transparentOverlay={false}>
      <ZeroSignalMap
        groups={!filter.group ? undefined : [filter.group.value]}
        homeLocations={!filter.homeLocation ? undefined : [filter.homeLocation.value.id]}
        zoneId={filter.zone?.value.id}
        timePeriod={timePeriod.value}
        simProvider={simProvider?.value}
      />
    </Loading>
  </>;
};

export const NoServiceTab = () => {
  const { t } = useTranslation('maps');

  const {
    filter,
    optionsLoading,
    ...filterPickerProps
  } = useFilterPicker();

  const {
    timePeriod,
    ...timePeriodPickerProps
  } = useTimePeriodPicker({ timePeriods: [30, 7, 2] });

  const { simProviderOptions, simProvider, setSimProvider, simProvidersLoading } = useSimProviderOptions();

  return <>
    <div className="core-maps_header">
      <div className="core-maps_headings">
        <h2>{t('NO_SERVICE_SUBHEADING')}</h2>
      </div>
      <div className="core-maps_filters">
        <div className="core-maps-options-filters">
          <OptionPicker
            options={simProviderOptions}
            onSelect={setSimProvider}
            current={simProvider}
            disabled={simProvidersLoading}
            selectText={t('SIM_PROVIDERS')}
            id="sim-provider-picker"
          />
        </div>
        <TimePeriodAndFilterPicker
          filter={filter}
          {...filterPickerProps}
          timePeriod={timePeriod}
          {...timePeriodPickerProps}
          className='core-maps_time-and-estate-filters'
        />
      </div>
    </div>
    <Loading isLoading={optionsLoading || simProvidersLoading} transparentOverlay={false}>
      <NoServiceMap
        groups={!filter.group ? undefined : [filter.group.value]}
        homeLocations={!filter.homeLocation ? undefined : [filter.homeLocation.value.id]}
        zoneId={filter.zone?.value.id}
        timePeriod={timePeriod.value}
        simProvider={simProvider?.value}
      />
    </Loading>
  </>;
};

export const CoverageTab = () => {
  const ns = 'maps';
  const ns1 = 'translation';
  const { t } = useTranslation([ns, ns1]);

  const {
    filter,
    optionsLoading,
    ...filterPickerProps
  } = useFilterPicker();
  const { group, homeLocation, zone } = filter;

  const {
    timePeriod,
    ...timePeriodPickerProps
  } = useTimePeriodPicker({ timePeriods: [30, 7, 2] });

  const { simProviderOptions, simProvider, setSimProvider, simProvidersLoading } = useSimProviderOptions();
  const bearerTypesRadioList: string[] = [null, '2g', '3g', '4g', '5g'];
  const [selectedBearer, setSelectedBearer] = React.useState<string>(null);

  const coverageMapProps = React.useMemo<CoverageMapProps>(() => {
    return {
      groups: !group ? undefined : [group.value],
      homeLocations: !homeLocation ? undefined : [homeLocation.value.id],
      zoneId: zone?.value.id,
      timePeriod: timePeriod.value,
      simProvider: simProvider?.value,
      bearerType: selectedBearer
    };
  }, [group, homeLocation, zone, timePeriod, simProvider, selectedBearer]);

  return <>
    <div className="core-maps_header">
      <div className="core-maps_headings">
        <h2>{t('COVERAGE_SUBHEADING', { ns })}</h2>
      </div>
      <div className="core-maps_filters">
        <div className="core-maps-options-filters">
          <OptionPicker
            options={simProviderOptions}
            onSelect={setSimProvider}
            current={simProvider}
            disabled={simProvidersLoading}
            selectText={t('SIM_PROVIDERS', { ns })}
            id="sim-provider-picker"
          />
          <div className="radio-buttons">
            {bearerTypesRadioList.map((bearerType => {
              const selected = bearerType === selectedBearer;

              return <ChipButton
                key={bearerType || 'All'}
                text={bearerType?.toUpperCase() || t('ALL', { ns: ns1 })}
                active={selected}
                onClick={() => {
                  if (bearerType !== selectedBearer) {
                    setSelectedBearer(bearerType);
                  }
                }}
              />;
            }))}
          </div>
        </div>
        <TimePeriodAndFilterPicker
          filter={filter}
          {...filterPickerProps}
          timePeriod={timePeriod}
          {...timePeriodPickerProps}
          className='core-maps_time-and-estate-filters'
        />
      </div>
    </div>
    <Loading isLoading={optionsLoading || simProvidersLoading} transparentOverlay={false}>
      <CoverageMap
        {...coverageMapProps}
      />
    </Loading>
  </>;
};

export interface ITabMap {
  tab: React.FC,
  title: keyof ITranslationKeys['maps']
}

export const tabsMap: ITabMap[] = [
  { title: 'NO_SERVICE_TAB', tab: NoServiceTab },
  { title: 'VERY_POOR_NO_SERVICE_TAB', tab: PoorNoSignalTab },
  { title: 'COVERAGE_TAB', tab: CoverageTab }
];
